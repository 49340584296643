import Konva from "konva";
import { closestPoint, pointDistance } from "./GeomUtils";

// Get the current pointer position and adjust it based on stage position
export const getWorldPosition = (stage: Konva.Stage) => {
  const pointerPosition = stage.getPointerPosition();
  if (pointerPosition) {
    const transform = stage.getAbsoluteTransform().copy();
    transform.invert();
    const worldPosition = transform.point(pointerPosition);
    return worldPosition;
  }
  return null;
};

// Snap mouse position
export const getSnappedPoint = (
  stage: Konva.Stage,
  baseLinePoints: number[][]
) => {
  const snapDistance = 20;
  const pos = getWorldPosition(stage);
  const currentPos = pos ? [pos.x, pos.y] : [0, 0];

  if (baseLinePoints.length > 0) {
    const snapCandidate = closestPoint(baseLinePoints, currentPos);
    if (pointDistance(snapCandidate, currentPos) <= snapDistance) {
      return snapCandidate;
    }
  }
  return currentPos;
};
