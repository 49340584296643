import {
  Abstract,
  AbstractDesignatorObject,
  DesignObject,
  EasyJoin,
  InternalDetail,
  LineObject,
  Material,
  NamedObject,
} from "../hooks/useDrawContext";

export const abstractify = <T extends AbstractDesignatorObject>(
  obj: T
): Abstract<T> => {
  if (obj === undefined) {
    return obj;
  }
  const { uuid, type } = obj;
  return { uuid, type } as Abstract<T>;
};

export const isNamedObject = (
  obj: AbstractDesignatorObject
): obj is Abstract<NamedObject> => {
  return obj.type !== "DesignObject";
};

export const isDesignObject = (
  obj: AbstractDesignatorObject
): obj is Abstract<DesignObject> => {
  return obj?.type === "DesignObject";
};

export const isLineObject = (obj: DesignObject): obj is LineObject => {
  return isDesignObject(obj) && obj.designObjectType === "LineObject";
};

export const isInternalDetail = (obj: NamedObject): obj is InternalDetail => {
  return isNamedObject(obj) && obj.type === "InternalDetail";
}

export const priorityOfMaterial = (easyjoin: EasyJoin, material: Material | Abstract<Material>) => {
  return easyjoin.priorities.findIndex(x => x.uuid === material.uuid);
}

export const isOverwritten = <T extends NamedObject>(
  obj: Abstract<T>
): boolean => {
  return Object.values(obj).filter((e) => e).length > 2;
};
