import {
  DrawContextStateProps,
  LineType,
  LineWeight,
  HatchType,
  ColorStyle,
  DesignatorLayer,
  Material,
  CrossSection,
  EasyJoin,
  DesignObject,
  AnyDesignObject,
} from "../hooks/useDrawContext";

import { v4 as uuid } from "uuid";
import { abstractify } from "./ObjectUtils";

const linetypes: { [name: string]: LineType } = {
  linetype1: {
    uuid: uuid(),
    type: "LineType",
    name: "linetype1",
  },
};

const lineweights: { [name: string]: LineWeight } = {
  lineweight1: {
    uuid: uuid(),
    name: "lineweight1",
    type: "LineWeight",
    value: 2,
  },
};

const hatchtypes: { [name: string]: HatchType } = {
  hatchtype1: {
    uuid: uuid(),
    name: "hatchtype1",
    type: "HatchType",
    value: "vertical",
  },
};

const colors: { [name: string]: ColorStyle } = {
  cad1: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ff0000",
    name: "CAD 1",
  },
  cad2: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ffff00",
    name: "CAD 2",
  },
  cad3: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#00ff00",
    name: "CAD 3",
  },
  cad4: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#00ffff",
    name: "CAD 4",
  },
  cad5: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#0000ff",
    name: "CAD 5",
  },
  cad6: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ff00ff",
    name: "CAD 6",
  },
  cad7: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#ffffff",
    name: "CAD 7",
  },
  cad8: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#808080",
    name: "CAD 8",
  },
  cad9: {
    uuid: uuid(),
    type: "ColorStyle",
    value: "#c0c0c0",
    name: "CAD 9",
  },
};

const layers: { [name: string]: DesignatorLayer } = {
  default: {
    uuid: uuid(),
    type: "DesignatorLayer",
    name: "Default",
    activated: true,
    linetype: abstractify(linetypes.linetype1),
    lineweight: abstractify(lineweights.lineweight1),
    color: abstractify(colors.cad1),
  },
};

const materials: { [name: string]: Material } = {
  mat1: {
    uuid: uuid(),
    type: "Material",
    name: "Material 1",
    thickness: 10,
    hatch: abstractify(hatchtypes.hatchtype1),
    hatchLayer: abstractify(layers.default),
    outlineLayer: abstractify(layers.default),
  },
  mat2: {
    uuid: uuid(),
    type: "Material",
    name: "Material 2",
    thickness: 20,
    hatch: abstractify(hatchtypes.hatchtype1),
    hatchLayer: abstractify(layers.default),
    outlineLayer: abstractify(layers.default),
  },
  mat3: {
    uuid: uuid(),
    type: "Material",
    name: "Material 3",
    thickness: 30,
    hatch: abstractify(hatchtypes.hatchtype1),
    hatchLayer: abstractify(layers.default),
    outlineLayer: abstractify(layers.default),
    variable: true,
  },
};

const cross_sections: { [name: string]: CrossSection } = {
  cs1: {
    uuid: uuid(),
    name: "Section Element 1",
    type: "InternalDetail",
    baseCoordinates: { type: "center", materialIndex: 0, value: 2 },
    internalDetailType: "CrossSection",
    materials: [abstractify(materials.mat1), abstractify(materials.mat2)],
    thickness: 33,
  },
  cs2: {
    uuid: uuid(),
    name: "Section Element 2",
    type: "InternalDetail",
    baseCoordinates: { type: "center", materialIndex: 0, value: 2 },
    internalDetailType: "CrossSection",
    materials: [abstractify(materials.mat2), abstractify(materials.mat3)],
    thickness: 33,
  },
  cs3: {
    uuid: uuid(),
    name: "Section Element 3",
    type: "InternalDetail",
    baseCoordinates: { type: "center", materialIndex: 0, value: 2 },
    internalDetailType: "CrossSection",
    materials: [abstractify(materials.mat3), abstractify(materials.mat1)],
    thickness: 33,
  },
};

const easyjoins: { [name: string]: EasyJoin } = {
  ej1: {
    uuid: uuid(),
    name: "Wall join 1",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [abstractify(materials.mat1)],
  },
  ej2: {
    uuid: uuid(),
    name: "Wall join 2",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [abstractify(materials.mat1)],
  },
  ej3: {
    uuid: uuid(),
    name: "Wall join 3",
    type: "BoundaryDetail",
    boundaryDetailType: "EasyJoin",
    priorities: [abstractify(materials.mat1)],
  },
};

const design_objects: {
  [name: string]: AnyDesignObject;
} = {
  do1: {
    type: "DesignObject",
    layer: layers.default,
    uuid: uuid(),
    designObjectType: "LineObject",
    geometry: { points: [100, 100, 500, 100] },
    additionalParams: {},
    details: [],
    editable: true,
  },
};

export const getInitialDrawContext = (): DrawContextStateProps => {
  return {
    mode: "base",
    scope: null,
    layers: {
      all: Object.values(layers).map((l) => abstractify(l)),
      current: abstractify(layers.default),
    },
    namedObjects: [
      linetypes,
      lineweights,
      hatchtypes,
      colors,
      layers,
      materials,
      cross_sections,
      easyjoins,
    ].reduce(
      (prev_obj, curr_obj) => ({
        ...prev_obj,
        ...Object.values(curr_obj).reduce(
          (prev, curr) => ({ ...prev, [curr.uuid]: curr }),
          {}
        ),
      }),
      {}
    ),
    designObjects: [design_objects].reduce(
      (prev_obj, curr_obj) => ({
        ...prev_obj,
        ...Object.values(curr_obj).reduce(
          (prev, curr) => ({ ...prev, [curr.uuid]: curr }),
          {}
        ),
      }),
      {}
    ),
    current_selection: [],
  };
};
