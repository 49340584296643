import { useRef, useState, useEffect } from "react";

import styles from "./CustomInput.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const CustomInput = ({
  initial = "",
  className,
  onClick,
  onChange,
  ...props
}: any) => {
  const inputRef = useRef(null);
  const [value, setValue] = useState(initial); // initial 값으로 초기화

  useEffect(() => {
    // 처음 로드될 때 input에 value 설정
    setValue(initial);
  }, [initial]);

  // 클릭 시 전체 텍스트 자동 선택
  const handleClick = (e) => {
    if (inputRef.current) {
      inputRef.current.select(); // 텍스트 전체 선택
    }
    onClick?.(e); // 외부에서 전달된 onClick 이벤트 실행
  };

  const handleChange = (e) => {
    const newValue = e.target.value;

    // value 상태를 업데이트
    setValue(newValue);

    onChange?.(e); // 외부에서 전달된 onChange 이벤트 실행
  };

  return (
    <input
      ref={inputRef}
      className={cx("wrapper") + " " + className}
      onClick={handleClick} // 클릭 시 텍스트 전체 선택
      onChange={handleChange} // 입력 시 값 업데이트
      value={value} // value 값으로 input 내용 설정
      {...props} // 나머지 props (예: placeholder, type 등)도 전달
    />
  );
};

export default CustomInput;
