import { Fragment, useMemo, useState } from "react";
import List from "./List";

import styles from "./PropertySection.module.scss";
import classNames from "classnames/bind";
import { ReactComponent as IconActionAdd } from "../svgs/IconActionAdd.svg";
import { ReactComponent as TempAlign } from "../svgs/TempAlign.svg";
import { ReactComponent as TempModify } from "../svgs/TempModify.svg";
import Division from "./Division";
import PropertyRow from "./PropertyRow";
import PropertyBlock from "./PropertyBlock";
import PropertyGroup from "./PropertyGroup";
import AutoScrollContainer from "./AutoScrollContainer";
import Account from "./Account";
import IconSymbol from "./IconSymbol";

import StandardSlabImg from "../imgs/standard_slab.png";
import DropdownCommand from "./DropdownCommand";
import useDrawContext, {
  BoundaryDetail,
  DesignatorLayer,
  InternalDetail,
  LineObject,
} from "../hooks/useDrawContext";
import {
  abstractify,
  isDesignObject,
  isOverwritten,
} from "../utils/ObjectUtils";
import PropertyRowObject from "./PropertyRowObject";
import DraggableList from "./DraggableList";

const cx = classNames.bind(styles);

const PropertySection = ({}: any) => {
  const {
    draw_context,
    getNamedObject,
    getDesignObject,
    getNamedObjectsByType,
    updateDesignObject,
    updateNamedObject,
  } = useDrawContext();
  const { curr, curr_data } = useMemo(() => {
    const curr = draw_context.current_selection[0];
    return {
      curr,
      curr_data: (isDesignObject(curr)
        ? getDesignObject(curr)
        : getNamedObject(curr)) as LineObject | DesignatorLayer | undefined,
    };
  }, [draw_context]);

  return (
    <div className={cx("wrapper")}>
      <List type="column" attach="start" fill>
        <List
          type="row"
          attach="space"
          padding={0.5}
          fill
          style={{
            minHeight: "2rem",
          }}
        >
          <Account
            symbol="C"
            name="Changyong Kim"
            background_color="#075662"
            color="#f0da55"
          />
          <IconActionAdd />
        </List>
        <Division />

        <AutoScrollContainer>
          <List type="column" attach="start" align="left" fill>
            {curr_data?.type === "DesignatorLayer" ? (
              <>
                <PropertyGroup title="Selection (1)">
                  <PropertyRow>
                    <PropertyBlock
                      type="text-input-fill"
                      name="Name"
                      value={curr_data.name}
                      onChange={(event) =>
                        updateNamedObject(abstractify(curr_data), {
                          name: event.target?.value,
                        })
                      }
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="object-selection"
                      object_type="ColorStyle"
                      value={getNamedObject(curr_data.color).name}
                      onClick={(e) =>
                        updateNamedObject(abstractify(curr_data), {
                          color: abstractify(e),
                        })
                      }
                    >
                      <div
                        className={cx("frame-color")}
                        style={{
                          backgroundColor: getNamedObject(curr_data.color)
                            .value,
                        }}
                      />
                    </PropertyBlock>
                    <PropertyBlock
                      type="action"
                      icon_type="break"
                      illust="Detach Style"
                    />
                    <PropertyBlock
                      type="action"
                      icon_type="match"
                      illust="Match Style"
                    />
                    <PropertyBlock
                      type="action"
                      icon_type={curr_data.activated ? "show" : "hide"}
                      illust={curr_data.activated ? "Shown" : "Hidden"}
                      onClick={() => {
                        updateNamedObject(abstractify(curr_data), {
                          activated: !curr_data.activated,
                        });
                      }}
                    />
                    <PropertyBlock
                      type="action"
                      icon_type={curr_data.locked ? "locked" : "unlocked"}
                      illust={curr_data.locked ? "Locked" : "Unlocked"}
                      onClick={() => {
                        updateNamedObject(abstractify(curr_data), {
                          locked: !curr_data.locked,
                        });
                      }}
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="object-selection"
                      object_type={"LineweightStyle"}
                      value="CTB"
                    >
                      <IconSymbol type="lineweight" />
                    </PropertyBlock>
                    <PropertyBlock
                      type="action"
                      icon_type="break"
                      illust="Detach style"
                    />
                    <PropertyBlock
                      type="action"
                      icon_type="match"
                      illust="Match Style"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="object-selection"
                      object_type={"LinetypeStyle"}
                      value="DASHDOT"
                    >
                      <IconSymbol type="linetype" />
                    </PropertyBlock>
                    <PropertyBlock
                      type="action"
                      icon_type="break"
                      illust="Detach style"
                    />
                    <PropertyBlock
                      type="action"
                      icon_type="match"
                      illust="Match Style"
                    />
                  </PropertyRow>
                </PropertyGroup>
              </>
            ) : curr_data?.type === "DesignObject" ? (
              <>
                <PropertyGroup title="Selection (1)" close>
                  <PropertyRow>
                    <PropertyBlock
                      type="text-input-fill"
                      name="Name"
                      value="Wall1"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="text-selection-fill"
                      name="Layer"
                      value="Wall"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock type="only-selection" value="2D Line" />
                    <PropertyBlock
                      type="action"
                      icon_type="show"
                      illust="Shown"
                    />
                    <PropertyBlock
                      type="action"
                      icon_type="unlocked"
                      illust="Unlocked"
                    />
                  </PropertyRow>
                  <TempAlign />
                </PropertyGroup>
                <PropertyGroup title="Configuration (1)" close>
                  <PropertyRow>
                    <PropertyBlock type="object-selection" value="Top Left">
                      <IconSymbol type="aligntopleft" />
                    </PropertyBlock>
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock type="text-input" name="X" value="-829" />
                    <PropertyBlock type="text-input" value="549" name="Y" />
                    <PropertyBlock
                      type="action"
                      icon_type="absoluteposition"
                      illust="Absolute position"
                      disabled
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock type="text-input" value="50" name="W" />
                    <PropertyBlock type="text-input" value="10" name="H" />
                    <PropertyBlock
                      type="action"
                      icon_type="breaken"
                      illust="Not proportional"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock type="object-input" value="30˚">
                      <IconSymbol type="angle" />
                    </PropertyBlock>
                    <TempModify style={{ minWidth: "8rem" }} />
                  </PropertyRow>
                </PropertyGroup>
                <PropertyGroup title="Geometry" close>
                  <PropertyRow>
                    <PropertyBlock
                      type="text-input-fill"
                      name="Start Point"
                      value="-829, -539"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="text-input-fill"
                      name="End Point"
                      value="-829, -539"
                    />
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock type="text-input" value="52.296" name="L" />
                    <PropertyBlock type="object-input" value="45.23˚">
                      <IconSymbol type="angle" />
                    </PropertyBlock>
                    <PropertyBlock
                      type="action"
                      icon_type="2dccw"
                      illust="Flip orientation"
                    />
                  </PropertyRow>
                </PropertyGroup>
                <PropertyGroup
                  title="Detail"
                  actions={[
                    { icon_type: "assets", illust: "Assets" },
                    curr_data.details.length === 0
                      ? {
                          icon_type: "add",
                          illust: "Add",
                          onClick: () => {
                            updateDesignObject(abstractify(curr_data), {
                              details: [
                                {
                                  internalDetails: [
                                    abstractify(
                                      Object.values(
                                        getNamedObjectsByType<InternalDetail>(
                                          "InternalDetail"
                                        )
                                      )?.[0]
                                    ),
                                  ],
                                  boundaryDetails: [
                                    abstractify(
                                      Object.values(
                                        getNamedObjectsByType<BoundaryDetail>(
                                          "BoundaryDetail"
                                        )
                                      )?.[0]
                                    ),
                                  ],
                                },
                              ],
                            });
                          },
                        }
                      : {
                          icon_type: "delete",
                          illust: "Delete",
                          onClick: () => {
                            updateDesignObject(abstractify(curr_data), {
                              details: [],
                            });
                          },
                        },
                  ]}
                >
                  {curr_data.details.map((each_detail, each_detail_idx) => (
                    <Fragment key={each_detail_idx}>
                      <PropertyRow>
                        <PropertyBlock type="subgroup">
                          Internal Detail
                        </PropertyBlock>
                        <PropertyBlock
                          type="action"
                          icon_type="assets"
                          illust="Assets"
                        />
                        <PropertyBlock
                          type="action"
                          icon_type="add"
                          illust="Add"
                          use_dropdown
                        >
                          <DropdownCommand
                            onClick={() => {
                              updateDesignObject(abstractify(curr_data), {
                                details: [
                                  {
                                    internalDetails: [
                                      ...curr_data.details[0]?.internalDetails,
                                      abstractify(
                                        Object.values(
                                          getNamedObjectsByType<InternalDetail>(
                                            "InternalDetail"
                                          )
                                        )[0]
                                      ),
                                    ],
                                    boundaryDetails:
                                      curr_data.details[0]?.boundaryDetails,
                                  },
                                ],
                              });
                            }}
                          >
                            Add Cross Sections
                          </DropdownCommand>
                          <DropdownCommand>
                            Add Repeated Objects
                          </DropdownCommand>
                          <DropdownCommand>Add Aligned Objects</DropdownCommand>
                          <DropdownCommand>
                            Add Free Internal Detail
                          </DropdownCommand>
                        </PropertyBlock>
                      </PropertyRow>
                      {each_detail.internalDetails
                        .map((e) => ({ var: e, data: getNamedObject(e) }))
                        .filter(
                          (e) => e.data.internalDetailType === "CrossSection"
                        )
                        .map((cs, cs_idx) => (
                          <Fragment key={cs_idx}>
                            {cs_idx === 0 && (
                              <PropertyRow small>
                                <PropertyBlock type="property-name-group">
                                  Cross Section
                                </PropertyBlock>
                              </PropertyRow>
                            )}
                            <PropertyRowObject
                              object={cs.var}
                              onDelete={() => {
                                updateDesignObject(abstractify(curr_data), {
                                  details: curr_data.details.map((d) => ({
                                    ...d,
                                    internalDetails: d.internalDetails.filter(
                                      (d, d_idx) => d_idx !== cs_idx
                                    ),
                                  })),
                                });
                              }}
                              onClick={(e) => {
                                updateDesignObject(abstractify(curr_data), {
                                  details: curr_data.details.map((d) => ({
                                    ...d,
                                    internalDetails: d.internalDetails.map(
                                      (d, d_idx) =>
                                        d_idx !== cs_idx ? d : abstractify(e)
                                    ),
                                  })),
                                });
                              }}
                              onEdit={(e) => {
                                updateDesignObject(abstractify(curr_data), {
                                  details: curr_data.details.map((d) => ({
                                    ...d,
                                    internalDetails: d.internalDetails.map(
                                      (d, d_idx) => (d_idx !== cs_idx ? d : e)
                                    ),
                                  })),
                                });
                              }}
                            >
                              <img src={StandardSlabImg} />
                            </PropertyRowObject>
                          </Fragment>
                        ))}
                      <PropertyRow>
                        <PropertyBlock type="subgroup">
                          Boundary Detail
                        </PropertyBlock>
                        <PropertyBlock
                          type="action"
                          icon_type="assets"
                          illust="Assets"
                        />
                        <PropertyBlock
                          type="action"
                          icon_type="add"
                          illust="Add"
                          use_dropdown
                        >
                          <DropdownCommand
                            onClick={() => {
                              updateDesignObject(abstractify(curr_data), {
                                details: [
                                  {
                                    internalDetails:
                                      curr_data.details[0]?.internalDetails,
                                    boundaryDetails: [
                                      ...curr_data.details[0]?.boundaryDetails,
                                      abstractify(
                                        Object.values(
                                          getNamedObjectsByType<BoundaryDetail>(
                                            "BoundaryDetail"
                                          )
                                        )[0]
                                      ),
                                    ],
                                  },
                                ],
                              });
                            }}
                          >
                            Add Easy Join
                          </DropdownCommand>
                          <DropdownCommand>Add Aligned Objects</DropdownCommand>
                          <DropdownCommand>
                            Add Free Boundary Detail
                          </DropdownCommand>
                        </PropertyBlock>
                      </PropertyRow>
                      {each_detail.boundaryDetails
                        .map((e) => getNamedObject(e))
                        .filter((e) => e.boundaryDetailType === "EasyJoin")
                        .map((ej, ej_idx) => (
                          <Fragment>
                            {ej_idx === 0 && (
                              <PropertyRow small>
                                <PropertyBlock type="property-name-group">
                                  Easy Join
                                </PropertyBlock>
                              </PropertyRow>
                            )}
                            <PropertyRowObject
                              object={abstractify(ej)}
                              onDelete={() => {
                                updateDesignObject(abstractify(curr_data), {
                                  details: curr_data.details.map((d) => ({
                                    ...d,
                                    boundaryDetails: d.boundaryDetails.filter(
                                      (d, d_idx) => d_idx !== ej_idx
                                    ),
                                  })),
                                });
                              }}
                              onClick={(e) => {
                                updateDesignObject(abstractify(curr_data), {
                                  details: curr_data.details.map((d) => ({
                                    ...d,
                                    boundaryDetails: d.boundaryDetails.map(
                                      (d, d_idx) => (d_idx !== ej_idx ? d : e)
                                    ),
                                  })),
                                });
                              }}
                            >
                              <IconSymbol type="easyjoin" />
                            </PropertyRowObject>
                          </Fragment>
                        ))}
                    </Fragment>
                  ))}
                </PropertyGroup>
                <PropertyGroup
                  title="Additional Parameters"
                  actions={[
                    { icon_type: "filter", illust: "Filter" },
                    { icon_type: "add", illust: "Add" },
                  ]}
                  close
                >
                  <PropertyRow>
                    <PropertyBlock
                      type="long-selection"
                      name="IFC type"
                      value="Wall"
                    ></PropertyBlock>
                  </PropertyRow>
                  <PropertyRow>
                    <PropertyBlock
                      type="long-text"
                      name="Owner"
                      value="Changyong"
                    ></PropertyBlock>
                  </PropertyRow>
                </PropertyGroup>
              </>
            ) : (
              <></>
            )}

            <div style={{ minHeight: "8rem", minWidth: "15rem" }}></div>
          </List>
        </AutoScrollContainer>
        <Division />
        <List type="column" attach="start" align="left">
          <PropertyGroup
            title="Project Info"
            actions={[{ icon_type: "more", illust: "More info" }]}
            close
          >
            <PropertyRow>
              <PropertyBlock type="long-text" name="Title" value="Project 1" />
            </PropertyRow>
          </PropertyGroup>
          <PropertyGroup
            title="Drawing Info"
            actions={[{ icon_type: "more", illust: "More info" }]}
            no_divider
            close
          >
            <PropertyRow>
              <PropertyBlock type="long-text" name="No." value="A-701" />
            </PropertyRow>
          </PropertyGroup>
        </List>
        <Division />
        <div style={{ minHeight: "64px" }}>
          <PropertyRow>
            <PropertyBlock type="group-title">Export</PropertyBlock>
            <PropertyBlock
              type="action"
              icon_type="copy"
              illust="Copy"
              // disabled
            />
            <PropertyBlock
              type="action"
              icon_type="download"
              illust="Download"
              onClick={() => {
                const fileUrl = "/temp.txt"; // 다운로드할 파일의 경로
                const link = document.createElement("a");
                link.href = fileUrl;
                link.download = "A-701.dxf"; // 다운로드될 파일 이름 지정
                link.click();
              }}
            />
            <PropertyBlock
              type="action"
              icon_type="more"
              illust="More options"
            />
          </PropertyRow>
        </div>
      </List>
    </div>
  );
};

export default PropertySection;
