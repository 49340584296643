import React, { useState } from "react";

import styles from "./PropertyBlock.module.scss";
import classNames from "classnames/bind";

import List from "./List";

import Tooltip from "./Tooltip";
import IconAction from "./IconAction";
import { ReactComponent as Toggle } from "../svgs/Toggle.svg";
import CustomInput from "./CustomInput";
import IconCommand from "./IconCommand";
import Box from "./Box";
import Dropdown from "./Dropdown";
import DropdownObject from "./DropdownObject";

const cx = classNames.bind(styles);

const PropertyBlock = ({
  type = "group-title",
  icon_type = "",
  illust,
  children,
  placeholder = "",
  disabled,
  inactivated,
  hide,
  name,
  value,
  overwritten,
  object_type,
  use_dropdown,
  onChange = (e) => {
    console.log("changed");
  },
  onClick = (e) => {
    console.log("clicked");
  },
  style,
}: any) => {
  const [tooltip, setTooltip] = useState(false);
  const [clicked, setClicked] = useState(false);

  return type === "group-title" ? (
    <div className={cx(type, "txt-bold", "txt-left")} style={style}>
      {children}
    </div>
  ) : type === "action" ? (
    <List
      attach="center"
      align="center"
      style={{
        position: "relative",
        minWidth: "2rem",
        minHeight: "2rem",
        ...style,
      }}
    >
      <div
        className={cx(
          "frame-action",
          (hide || disabled) && "disabled",
          hide && "hide",
          use_dropdown && clicked && "clicked"
        )}
        onMouseOver={() => setTooltip(true)}
        onMouseLeave={() => setTooltip(false)}
        onClick={(e) => {
          if (!disabled && !hide) {
            onClick();
            setTooltip(false);
            setClicked((b) => !b);
          }
          e.stopPropagation();
        }}
      >
        <IconAction type={icon_type} />
        {tooltip && illust && !hide && !disabled && (
          <Tooltip type="top">{illust}</Tooltip>
        )}
      </div>
      {use_dropdown && clicked && (
        <Dropdown color="dark" onClose={() => setClicked(false)}>
          {children.map((child, idx) =>
            React.cloneElement(child, {
              key: idx,
              onClick: (e) => {
                child.props?.onClick?.(e);
                setClicked(false);
              },
            })
          )}
        </Dropdown>
      )}
    </List>
  ) : type === "object-input" ? (
    <div
      className={cx(
        type,
        hide && "hide",
        overwritten && "overwritten",
        inactivated && "inactivated"
      )}
      style={style}
    >
      <List type="row" fillX>
        <Box width={2} height={2}>
          {children}
        </Box>
        <CustomInput
          className={cx(
            "frame-input",
            "txt-regular",
            "txt-left",
            disabled && "disabled"
          )}
          initial={value ?? placeholder}
          onChange={onChange}
        />
      </List>
    </div>
  ) : type === "text-input-fill" ? (
    <div className={cx(type, hide && "hide")}>
      <List type="row" attach="center" fill fillY>
        <div className={cx("frame-name")}>{name}</div>
        <CustomInput
          className={cx(
            "frame-input",
            "frame-value",
            "txt-regular",
            "txt-left",
            disabled && "disabled"
          )}
          initial={value}
          onChange={onChange}
        />
      </List>
    </div>
  ) : type === "text-selection-fill" ? (
    <div className={cx(type, hide && "hide")}>
      <List type="row" attach="center" fill fillY>
        <div className={cx("frame-name")}>{name}</div>
        <List type="row" style={{ width: "6rem" }}>
          <div
            className={cx(
              "frame-value",
              "txt-regular",
              "txt-left",
              "fit",
              disabled && "disabled"
            )}
          >
            {value}
          </div>
          <div className={cx("frame-dropdown")}>
            <List type="row" attach="center" fill fillY>
              <Toggle />
            </List>
          </div>
        </List>
      </List>
    </div>
  ) : type === "only-selection" ? (
    <div className={cx(type, hide && "hide")}>
      <List type="row" fill fillY>
        <div
          className={cx(
            "frame-value",
            "txt-regular",
            "txt-left",
            "fit",
            disabled && "disabled"
          )}
        >
          {value}
        </div>
        <div className={cx("frame-dropdown")}>
          <List type="row" attach="center" fill fillY>
            <Toggle />
          </List>
        </div>
      </List>
    </div>
  ) : type === "object-selection" ? (
    <div
      className={cx(
        type,
        hide && "hide",
        overwritten && "overwritten",
        clicked && "clicked"
      )}
    >
      <List type="row" fill fillY onClick={() => setClicked((b) => !b)}>
        <Box width={2} height={2}>
          {children}
        </Box>
        <div
          className={cx(
            "frame-value",
            "txt-regular",
            "txt-left",
            "fit",
            disabled && "disabled"
          )}
        >
          {value}
        </div>
        <div className={cx("frame-dropdown")}>
          <List type="row" attach="center" fill fillY>
            <Toggle />
          </List>
        </div>
      </List>
      {clicked && (
        <Dropdown type="bottom-right" onClose={() => setClicked(false)}>
          <DropdownObject
            object_type={object_type}
            onClick={onClick}
            onClose={() => setClicked(false)}
          />
        </Dropdown>
      )}
    </div>
  ) : type === "object" ? (
    <div
      className={cx(
        type,
        hide && "hide",
        overwritten && "overwritten",
        clicked && "clicked"
      )}
    >
      <List
        type="row"
        fill
        fillY
        onClick={() => {
          onClick();
          setClicked((b) => !b);
        }}
      >
        <Box width={2} height={2}>
          {children}
        </Box>
        <div
          className={cx(
            "frame-value",
            "txt-regular",
            "txt-left",
            "fit",
            disabled && "disabled"
          )}
        >
          {value}
        </div>
      </List>
    </div>
  ) : type === "text-input" ? (
    <div className={cx(type, hide && "hide")}>
      <List type="row" fillX>
        <Box width={2} height={2}>
          <div className={cx("frame-name")}>{name}</div>
        </Box>
        <CustomInput
          className={cx(
            "frame-input",
            "txt-regular",
            "txt-left",
            disabled && "disabled"
          )}
          initial={value}
        />
      </List>
    </div>
  ) : type === "subgroup" ? (
    <List type="row" fill>
      <div className={cx(type, "bullet", overwritten && "overwritten")}>·</div>
      <div className={cx(type, "txt", overwritten && "overwritten")}>
        {children}
      </div>
    </List>
  ) : type === "property-name-group" ? (
    <div className={cx(type, "txt")}>{children}</div>
  ) : type === "long-text" ? (
    <div className={cx(type, hide && "hide")}>
      <List type="row" attach="center" fill fillY>
        <div className={cx("frame-name")}>{name}</div>
        <CustomInput
          className={cx(
            "frame-input",
            "frame-value",
            "txt-regular",
            "txt-left",
            disabled && "disabled"
          )}
          initial={value}
        />
      </List>
    </div>
  ) : type === "long-selection" ? (
    <div className={cx(type, hide && "hide")}>
      <List type="row" attach="center" fill fillY>
        <div className={cx("frame-name")}>{name}</div>
        <List type="row" fill style={{ width: "100%" }}>
          <div
            className={cx(
              "frame-value",
              "txt-regular",
              "txt-left",
              "fit",
              disabled && "disabled"
            )}
          >
            {value}
          </div>
          <div className={cx("frame-dropdown")}>
            <List type="row" attach="center" fill fillY>
              <Toggle />
            </List>
          </div>
        </List>
      </List>
    </div>
  ) : type === "overwritten" ? (
    <Box width={6} height={2}>
      <div className={cx("frame-overwritten")}>Overwritten</div>
    </Box>
  ) : null;
};

export default PropertyBlock;
