import { useEffect, useRef, useState } from "react";
import {
  Stage,
  Layer,
  Line,
  Text,
  Circle,
  Group,
  RegularPolygon,
} from "react-konva";
import useDrawContext, {
  BoundaryDetail,
  InternalDetail,
  LineObject,
} from "../hooks/useDrawContext";
import { v4 as uuid } from "uuid";
import { closestPoint, pointDistance } from "../utils/GeomUtils";
import { RenderCurrentDrawingLine, RenderDesignObject } from "./DesignObject";
import {
  abstractify,
  isDesignObject,
  isLineObject,
} from "../utils/ObjectUtils";
import useCanvasContext from "./CanvasContext";
import { scaleBy } from "../utils/CanvasConstants";
import Konva from "konva";
import { getSnappedPoint, getWorldPosition } from "../utils/KonvaUtils";

export const SelectedDesignObject = (draw_context) => {
  const selected = draw_context.current_selection.filter(isDesignObject);
  return selected.length > 0 ? selected[0] : null;
};

const CanvasWindow = ({ width, height }) => {
  const {
    draw_context,
    setDrawContext,
    getNamedObjectsByType,
    getDesignObject,
  } = useDrawContext();
  const { canvasContext, setCanvasContext } = useCanvasContext();
  const stageRef = useRef<Konva.Stage>(null);
  const [currentDrawing, setCurrentDrawing] = useState<number[]>([]);
  const [currentMousePos, setCurrentMousePos] = useState<number[]>([]);
  const [baseLinePoints, setBaseLinePoints] = useState<number[][]>([]);
  const [scale, setScale] = useState(1);
  const [isPanning, setIsPanning] = useState(false);
  const lastPos = useRef({ x: 0, y: 0 });

  useEffect(() => {
    Konva.pixelRatio = 1;
  }, []);

  const updateDesignObjectData = (newBaseLine: number[]) => {
    if (newBaseLine.length > 2) {
      const newLine: LineObject = {
        uuid: uuid(),
        type: "DesignObject",
        designObjectType: "LineObject",
        layer: { ...draw_context.layers.current },
        geometry: { points: newBaseLine.slice() },
        additionalParams: {},
        details: [
          {
            internalDetails: [
              abstractify(
                Object.values(
                  getNamedObjectsByType("InternalDetail")
                )[0] as InternalDetail
              ),
            ],
            boundaryDetails: [
              abstractify(
                Object.values(
                  getNamedObjectsByType("BoundaryDetail")
                )[0] as BoundaryDetail
              ),
            ],
          },
        ],
        editable: true,
      };
      setDrawContext({
        designObjects: {
          ...draw_context.designObjects,
          [newLine.uuid]: newLine,
        },
      });
    }
  };

  // Handle mode changes
  useEffect(() => {
    if (draw_context.mode === "base") {
      updateDesignObjectData(currentDrawing);
      setCurrentDrawing([]);
    }
    if (draw_context.mode !== "edit") {
      setDrawContext({ current_selection: [] });
    }
  }, [draw_context.mode]);

  // Update baseline points and joints
  useEffect(() => {
    const jointMap = new Map();
    const addJoint = (x: number, y: number, val: any) => {
      if (!jointMap.has(x)) {
        jointMap.set(x, new Map());
      }
      if (!jointMap.get(x).has(y)) {
        jointMap.get(x).set(y, []);
      }
      jointMap.get(x).get(y).push(val);
    };

    const getAllJoints = (jointMap) => {
      const allPoints = [];
      const allJoints: { [uuid: string]: any } = {};
      for (let [x, yMap] of jointMap) {
        for (let [y, anchors] of yMap) {
          allPoints.push([x, y]);
          if (anchors.length > 1) {
            const new_uuid = uuid();
            allJoints[new_uuid] = {
              uuid: new_uuid,
              type: "JointObject",
              anchors,
            };
          }
        }
      }
      return [allPoints, allJoints] as const;
    };

    for (const line of Object.values(draw_context.designObjects).filter(
      isLineObject
    )) {
      for (let i = 0; i < line.geometry.points.length; i += 2) {
        if (
          line.uuid !== SelectedDesignObject(draw_context)?.uuid ||
          i / 2 !== canvasContext.editPoint?.pointIndex
        ) {
          const [x, y] = line.geometry.points.slice(i, i + 2);
          addJoint(x, y, {
            designObject: abstractify(line),
            pointIndex: i / 2,
          });
        }
      }
    }

    const [points, joints] = getAllJoints(jointMap);
    setBaseLinePoints(points);
    setCanvasContext({ jointObjects: joints });
  }, [draw_context.designObjects]);

  // Click handler
  const handleStageClick = (e: any) => {
    const pos = getSnappedPoint(stageRef.current, baseLinePoints);
    if (draw_context.mode === "polyline") {
      if (e.evt.button === 0) {
        if (currentDrawing.length > 0) {
          updateDesignObjectData([...currentDrawing, ...pos]);
          setCurrentDrawing([]);
        } else {
          setCurrentDrawing([...currentDrawing, ...pos]);
        }
      } else if (e.evt.button === 2) {
        setCurrentDrawing([]);
      }
    } else if (draw_context.mode === "edit" && canvasContext.editPoint) {
      const selectedUuid = SelectedDesignObject(draw_context)?.uuid;
      const newDesignObject: LineObject = structuredClone(
        getDesignObject(selectedUuid)
      );
      newDesignObject.geometry.points[canvasContext.editPoint.pointIndex * 2] =
        pos[0];
      newDesignObject.geometry.points[
        canvasContext.editPoint.pointIndex * 2 + 1
      ] = pos[1];

      setDrawContext({
        designObjects: {
          ...draw_context.designObjects,
          [selectedUuid]: newDesignObject,
        },
        current_selection: [],
      });
      setCanvasContext({ editPoint: null });
    }
  };

  const handleStageDoubleClick = (e) => {
    if (draw_context.scope !== null) {
      setDrawContext({
        mode: "base",
        scope: null,
      });
    }
  };

  // Mouse move handler
  const handleMouseMove = (e: any) => {
    if (draw_context.mode === "polyline") {
      const pos = getSnappedPoint(stageRef.current, baseLinePoints);
      setCurrentMousePos(pos);
    }

    if (draw_context.mode === "edit" && canvasContext.editPoint) {
      const pos = getSnappedPoint(stageRef.current, baseLinePoints);
      setCurrentMousePos(pos);
      const newDesignObject: LineObject = structuredClone(
        getDesignObject(SelectedDesignObject(draw_context))
      );
      newDesignObject.geometry.points[canvasContext.editPoint.pointIndex * 2] =
        pos[0];
      newDesignObject.geometry.points[
        canvasContext.editPoint.pointIndex * 2 + 1
      ] = pos[1];

      setDrawContext({
        designObjects: {
          ...draw_context.designObjects,
          [newDesignObject.uuid]: newDesignObject,
        },
      });
    }

    if (isPanning && stageRef.current) {
      const stage = stageRef.current;
      const pos = stage.getPointerPosition();
      if (pos) {
        const dx = pos.x - lastPos.current.x;
        const dy = pos.y - lastPos.current.y;
        stage.x(stage.x() + dx);
        stage.y(stage.y() + dy);
        stage.batchDraw();
        lastPos.current = pos;
      }
    }
  };

  // Wheel zoom handler
  const handleWheelZoom = (e: any) => {
    e.evt.preventDefault();
    const stage = stageRef.current;
    const pointer = getWorldPosition(stageRef.current);
    const oldScale = stage.scaleX();
    const newScale = e.evt.deltaY < 0 ? oldScale * scaleBy : oldScale / scaleBy;
    setScale(newScale);
    if (!pointer) return; // 포인터 위치가 없으면 함수 종료
    stage.scale({ x: newScale, y: newScale });
    stage.position({
      x: stage.x() - (newScale - oldScale) * pointer.x,
      y: stage.y() - (newScale - oldScale) * pointer.y,
    });
    stage.batchDraw();
  };

  // Pan handler
  const handleMouseDown = (e: any) => {
    if (e.evt.button === 2) {
      // Middle mouse button (wheel click)
      e.evt.preventDefault();
      setIsPanning(true);
      const pos = stageRef.current?.getPointerPosition?.();
      if (pos) {
        lastPos.current = pos;
      }
    }
  };

  const handleMouseUp = (e: any) => {
    if (e.evt.button === 2) {
      setIsPanning(false);
    }
  };

  return (
    <Stage
      width={width}
      height={height}
      ref={stageRef}
      x={0} // 초기 x값
      y={0} // 초기 y값
      scaleX={1} // 초기 scale 값 (기본값: 1)
      scaleY={1}
      rotation={0} // 초기 회전 값 (기본값: 0)
      onClick={handleStageClick}
      onMouseMove={handleMouseMove}
      onWheel={handleWheelZoom}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
      onDblClick={handleStageDoubleClick}
      // onMouseLeave={() => setIsPanning(false)}
    >
      <Layer>
        <Text text={draw_context.mode} />
        <RenderCurrentDrawingLine
          currentDrawing={currentDrawing}
          currentMousePos={currentMousePos}
        />
        <RenderDesignObject />
      </Layer>
    </Stage>
  );
};

export default CanvasWindow;
