import React from "react";
import Konva from "konva";
import { Stage, Layer, Line, Circle, Text } from "react-konva";
import { CanvasLine } from "../canvas/CanvasLine";

const LineStyle1 = { stroke: "#5244F2", fill: "#5244F2", strokeWidth: 3 };
const LineStyle2 = { stroke: "#44A4F2", fill: "#44A4F2", strokeWidth: 3 };

const Homepage = () => {
  const [layer, setLayer] = React.useState("default");
  const [lines, setLines] = React.useState<CanvasLine[]>([]);
  const isDrawing = React.useRef(false);
  const isMoving = React.useRef(false);
  const [currentStart, setCurrentStart] = React.useState<number[]>([]);
  const [currentEnd, setCurrentEnd] = React.useState<number[]>([]);

  const [mode, setMode] = React.useState("drawMode");

  const onClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    console.log(lines);
    if (isDrawing.current) {
      const pos = e.target.getStage().getPointerPosition();
      setLines([
        ...lines,
        { layer, start: currentStart.slice(), end: [pos.x, pos.y] },
      ]);
      isDrawing.current = false;
    } else {
      const pos = e.target.getStage().getPointerPosition();
      setCurrentStart([pos.x, pos.y]);
      setCurrentEnd([pos.x, pos.y]);
      isDrawing.current = true;
    }
  };

  const onMove = (e: Konva.KonvaEventObject<MouseEvent>) => {
    if (!isDrawing.current) {
      return;
    }

    const pos = e.target.getStage().getPointerPosition();
    setCurrentEnd([pos.x, pos.y]);
  };

  const currentLine = () => {
    if (currentStart && currentEnd) {
      return (
        <Layer>
          <Circle
            x={currentStart[0]}
            y={currentStart[1]}
            radius={5}
            fill="#cccccc"
          />
          <Circle
            x={currentEnd[0]}
            y={currentEnd[1]}
            radius={5}
            fill="#cccccc"
          />
          <Line
            key="current"
            points={currentStart.concat(currentEnd)}
            stroke="#bbbbbb"
            strokeWidth={5}
          />
        </Layer>
      );
    }
  };

  const onCircleClick = (e: Konva.KonvaEventObject<MouseEvent>) => {
    console.log("clikcked circle");
  };

  const DrawnLine = (line: CanvasLine, i: number) => {
    let lineStyle;
    if (line.layer === "default") {
      lineStyle = LineStyle1;
    } else {
      lineStyle = LineStyle2;
    }

    // const newLine = Line({...lineStyle, points: line.start.concat(line.end), key: {i}});
    return (
      <>
        <Circle
          {...lineStyle}
          x={line.start[0]}
          y={line.start[1]}
          radius={3}
          onClick={onCircleClick}
        />
        <Circle
          {...lineStyle}
          x={line.end[0]}
          y={line.end[1]}
          radius={3}
          onClick={onCircleClick}
        />
        <Line {...lineStyle} points={line.start.concat(line.end)} key={i} />
      </>
    );
  };

  return (
    <div>
      <select
        value={layer}
        onChange={(e) => {
          setLayer(e.target.value);
        }}
      >
        <option value="default">Default</option>
        <option value="layer1">Layer 1</option>
      </select>
      <Stage
        width={window.innerWidth}
        height={window.innerHeight}
        onClick={onClick}
        onMouseMove={onMove}
      >
        <Layer>
          <Text text="test text" x={10} y={30} />
          {lines.map((line, i) => DrawnLine(line, i))}
        </Layer>
        {currentLine()}
      </Stage>
    </div>
  );
};

export default Homepage;
