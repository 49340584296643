

export const unitVector = (segment: number[]) => {
    const [x1, y1, x2, y2] = segment;

    const vector = [x2 - x1, y2 - y1];
    const length = ((x1 - x2) ** 2 + (y1 - y2) ** 2) ** 0.5;

    return vector.map((x) => x / length);
}

export const rotateVector = (vector: number[], ccw: boolean = true) => {
    const [u, v] = vector;
    var res
    if (ccw) {
        res = [-v, u];
    } else {
        res = [v, -u];
    }

    return res;
}


export const offsetSegment = (segment: number[], dist: number) => {
    const [x1, y1, x2, y2] = segment;

    const [u, v] = rotateVector(unitVector(segment));

    return [x1 + dist * u, y1 + dist * v, x2 + dist * u, y2 + dist * v];
}

export const joinSegment = (seg1: number[], seg2: number[]) => {
    const [x1, y1, x2, y2] = seg1;
    const [x3, y3, x4, y4] = seg2;

    const d = (x1 - x2) * (y3 - y4) - (y1 - y2) * (x3 - x4);

    if (d === 0) {
        return null;
    } else {
        const a = x1 * y2 - y1 * x2;
        const b = x3 * y4 - y3 * x4;
        const x = (a * (x3 - x4) - (x1 - x2) * b) / d;
        const y = (a * (y3 - y4) - (y1 - y2) * b) / d;

        return [x, y];
    }
}

export const offsetLine = (line: number[], dist: number) => {
    var segments = [];
    for (var i = 0; i < line.length - 3; i += 2) {
        segments.push(line.slice(i, i + 4));
    }

    var offsetSegs = segments.map((seg) => offsetSegment(seg, dist));

    var offsetPoints = [];

    offsetPoints = offsetPoints.concat(offsetSegs[0].slice(0, 2)); // first point

    for (var i = 0; i < offsetSegs.length - 1; i += 1) {
        const seg1 = offsetSegs[i];
        const seg2 = offsetSegs[i + 1];

        const nextPoint = joinSegment(seg1, seg2);
        if (nextPoint) {
            offsetPoints = offsetPoints.concat(nextPoint);
        }
    }

    offsetPoints = offsetPoints.concat(offsetSegs[offsetSegs.length - 1].slice(-2)); // last point

    return offsetPoints;
}

export const pointDistance = (p: number[], q: number[]) => {
    let [a, b] = p;
    let [c, d] = q;

    return Math.sqrt((a - c) ** 2 + (b - d) ** 2)
}

export const closestPoint = (points: number[][], target: number[]) => {
    return points.reduce((closest, point) => {
        const distance = pointDistance(point, target);
        if (distance < pointDistance(closest, target)) {
            return point;
        }
        return closest;
    });
}

export const getAngle = (line1: number[], line2: number[], inDegree: boolean = true) => {
    const [x1, y1, x2, y2] = line1;
    const [x3, y3, x4, y4] = line2;

    // Calculate direction vectors for both lines
    const dir1 = { x: x2 - x1, y: y2 - y1 };
    const dir2 = { x: x4 - x3, y: y4 - y3 };

    // Calculate the angle between the two direction vectors
    const angle1 = Math.atan2(dir1.y, dir1.x);
    const angle2 = Math.atan2(dir2.y, dir2.x);

    // Calculate the difference between the angles
    let angleDifference = angle2 - angle1;

    // Normalize the angle to be between 0 and 2 * Math.PI
    if (angleDifference < 0) {
        angleDifference += 2 * Math.PI;
    }

    // Convert to degrees if inDegree is true
    let result = inDegree ? angleDifference * (180 / Math.PI) : angleDifference;

    // Normalize to be between 0 and 360 degrees
    return inDegree ? (result % 360 + 360) % 360 : result;
}