import {
  Stage,
  Layer,
  Line,
  Circle,
  Text,
  Group,
  RegularPolygon,
} from "react-konva";
import useDrawContext, {
  Abstract,
  BoundaryDetail,
  CrossSection,
  DesignatorLayer,
  DesignObject,
  EasyJoin,
  LineObject,
} from "../hooks/useDrawContext";
import {
  closestPoint,
  offsetLine,
  joinSegment,
  getAngle,
  unitVector,
  rotateVector,
} from "../utils/GeomUtils";
import useCanvasContext, { JointObject } from "./CanvasContext";
import { abstractify, priorityOfMaterial } from "../utils/ObjectUtils";
import { SelectedDesignObject } from "./Canvas";
import { useEffect, useMemo, useState } from "react";
import {
  baseLineColor,
  basePointRadius,
  baseTriangleRadius,
  clickAreaWidth,
  drawingLineColor,
  drawingLineWidth,
  selectedColor,
} from "../utils/CanvasConstants";

export const LayerToLinestyle = (layer: DesignatorLayer) => {
  const { getNamedObject } = useDrawContext();
  return {
    stroke: getNamedObject(layer.color).value,
    fill: getNamedObject(layer.color).value,
    strokeWidth: getNamedObject(layer.lineweight).value,
  };
};

export const CalculateJointExtension = (joint: JointObject) => {
  const { getNamedObject, getDesignObject } = useDrawContext();

  var res = {};

  for (let i = 0; i < joint.anchors.length; i++) {
    for (let j = 0; j < joint.anchors.length; j++) {
      if (i === j) {
        continue;
      }

      let joint_seg1 = joint.anchors[i];
      let joint_seg2 = joint.anchors[j];

      let desobj1: LineObject = getDesignObject(joint_seg1.designObject);
      let desobj2: LineObject = getDesignObject(joint_seg2.designObject);

      let se1: CrossSection = getNamedObject(
        desobj1.details[0].internalDetails[0]
      ) as CrossSection;
      let se2: CrossSection = getNamedObject(
        desobj2.details[0].internalDetails[0]
      ) as CrossSection;

      let boundary_detail = getNamedObject(
        desobj1.details[0].boundaryDetails[0]
      ) as EasyJoin; // des obj 두개가 서로 다를때?

      // let dir = joint_seg1.pointIndex !== joint_seg2.pointIndex; // polyline 일 때 처리 다르게?
      let dir =
        getAngle(desobj1.geometry.points, desobj2.geometry.points) <= 180;

      let other_mat_ids = se2.materials.slice();
      if (!dir) {
        other_mat_ids.reverse();
      }

      let line1 = desobj1.geometry.points;
      let line2 = desobj2.geometry.points;

      var base_value1;
      if (se1.baseCoordinates.type === "center") {
        base_value1 = 0;
        for (let i = 0; i < se1.materials.length; i += 1) {
          let material = getNamedObject(se1.materials[i]);
          base_value1 += material.thickness;
        }
        base_value1 /= 2;
      } else {
        base_value1 = se1.baseCoordinates.value;
      }

      var base_value2;
      if (se2.baseCoordinates.type === "center") {
        base_value2 = 0;
        for (let i = 0; i < se2.materials.length; i += 1) {
          let material = getNamedObject(se2.materials[i]);
          base_value2 += material.thickness;
        }
        base_value2 /= 2;
      } else {
        base_value2 = se2.baseCoordinates.value;
      }

      var pos1 = 0;

      var desobj1_result = [];

      var mat2_id = 0;
      var pos2 = 0;
      let mat2;
      let mat2_index;
      let max_pos2 = se2.materials.reduce(
        (acc, curr) => acc + getNamedObject(curr).thickness,
        0
      );

      for (let mat1 of se1.materials) {
        mat1 = getNamedObject(mat1);

        let mat1_bottom_line = offsetLine(line1, pos1 - base_value1);
        pos1 += mat1.thickness;
        let mat1_top_line = offsetLine(line1, pos1 - base_value1);

        let mat1_index = priorityOfMaterial(boundary_detail, mat1);

        while (mat2_id < other_mat_ids.length) {
          mat2 = getNamedObject(other_mat_ids[mat2_id]);
          mat2_index = priorityOfMaterial(boundary_detail, mat2);

          if (mat1_index <= mat2_index) {
            break;
          }
          pos2 += mat2.thickness;
          mat2_id += 1;
        }

        if (pos2 >= max_pos2) {
          pos2 = max_pos2;
        }
        let mat2_bottom_line = offsetLine(
          line2,
          (pos2 - base_value2) * (dir ? 1 : -1)
        );
        pos2 += mat2.thickness;
        if (pos2 >= max_pos2) {
          pos2 = max_pos2;
        }
        let mat2_top_line = offsetLine(
          line2,
          (pos2 - base_value2) * (dir ? 1 : -1)
        );

        let top_bound;
        let bottom_bound;

        if (mat1_index === mat2_index) {
          if (dir) {
            top_bound = mat2_top_line.slice();
            bottom_bound = mat2_bottom_line.slice();
          } else {
            top_bound = mat2_bottom_line.slice();
            bottom_bound = mat2_top_line.slice();
          }
        } else {
          if (dir) {
            top_bound = mat2_top_line.slice();
            bottom_bound = mat2_top_line.slice();
          } else {
            top_bound = mat2_bottom_line.slice();
            bottom_bound = mat2_bottom_line.slice();
          }
        }

        if (top_bound && bottom_bound) {
          let top_point = joinSegment(mat1_top_line, top_bound);
          let bottom_point = joinSegment(mat1_bottom_line, bottom_bound);

          desobj1_result.push([top_point, bottom_point]);
        } else {
          desobj1_result.push([0, 0]);
        }
      }

      res[desobj1.uuid] = desobj1_result;
    }
  }
  return res;
};

export const RenderCurrentDrawingLine = ({
  currentDrawing,
  currentMousePos,
}) => {
  const line = (
    <Line
      points={currentDrawing.concat(currentMousePos)}
      stroke={drawingLineColor}
      strokeWidth={drawingLineWidth}
    />
  );
  const circle = (
    <Circle
      x={currentDrawing[0]}
      y={currentDrawing[1]}
      fill={drawingLineColor}
      radius={basePointRadius}
    />
  );

  const vector = unitVector(currentDrawing.concat(currentMousePos));
  const normal = rotateVector(vector, false);

  const rot = getAngle([0, 0, 1, 0], currentDrawing.concat(currentMousePos));

  const triangle1 = (
    <RegularPolygon
      sides={3}
      radius={baseTriangleRadius}
      x={currentMousePos[0]}
      y={currentMousePos[1]}
      rotation={rot + 90}
      fill={drawingLineColor}
    />
  );

  const tri2Point = [
    currentDrawing[0] + normal[0] * baseTriangleRadius * 2,
    currentDrawing[1] + normal[1] * baseTriangleRadius * 2,
  ];

  const line2 = (
    <Line
      points={currentDrawing.concat(tri2Point)}
      stroke={drawingLineColor}
      strokeWidth={drawingLineWidth}
    />
  );

  const triangle2 = (
    <RegularPolygon
      sides={3}
      radius={baseTriangleRadius}
      x={tri2Point[0]}
      y={tri2Point[1]}
      rotation={rot}
      fill={drawingLineColor}
    />
  );

  return (
    <Group>
      {line}
      {line2}
      {circle}
      {triangle1}
      {triangle2}
    </Group>
  );
};

const ClickArea = ({ design_object }: { design_object: LineObject }) => {
  const { draw_context, setDrawContext, getNamedObject } = useDrawContext();
  const linestyle = useMemo(
    () => LayerToLinestyle(getNamedObject(design_object.layer)),
    [design_object]
  );
  const [opacity, setOpacity] = useState(1);

  useEffect(() => {
    setTimeout(() => setOpacity(0), 10);
  }, []);

  const handleLineClick = (e) => {
    if (draw_context.mode === "base") {
      setDrawContext({
        mode: "edit",
        current_selection: [abstractify(e.target.attrs.design_object)],
      });
    } else if (
      draw_context.mode === "edit" &&
      SelectedDesignObject(draw_context) === null
    ) {
      setDrawContext({
        current_selection: [abstractify(e.target.attrs.design_object)],
      });
    }
  };

  const handleLineDoubleClick = (e) => {
    if (draw_context.scope === null) {
      setDrawContext({
        scope: abstractify(e.target.attrs.design_object),
        current_selection: [],
      });
    }
  };

  return (
    <Line
      points={design_object.geometry.points ?? [50, 50, 100, 100]}
      {...linestyle}
      //   opacity={opacity}
      strokeWidth={clickAreaWidth}
      design_object={design_object}
      onClick={handleLineClick}
      onDblClick={handleLineDoubleClick}
      strokeScaleEnabled={false}
      visible={false}
      listening={false}
    />
  );
};

export const RenderDesignObject = () => {
  const { draw_context, setDrawContext, getNamedObject } = useDrawContext();
  const { canvasContext, setCanvasContext } = useCanvasContext();

  // click on a point on a polyline
  const handlePointClick = (e) => {
    if (draw_context.mode === "edit") {
      let design_object: Abstract<DesignObject> = e.target.attrs.design_object;
      if (SelectedDesignObject(draw_context)?.uuid === design_object.uuid) {
        if (canvasContext.editPoint !== null) {
          setCanvasContext({ editPoint: null });
        } else {
          setCanvasContext({
            editPoint: {
              designObject: abstractify(SelectedDesignObject(draw_context)),
              pointIndex: e.target.attrs.line_index,
              originalCoordinates: [], // TODO
              currentCoordinates: [],
            },
          });
        }
      }
    }
  };

  let designObjectData = draw_context.designObjects;

  const renderBaseLine = (
    design_object: LineObject,
    opacity = 1,
    color = null
  ) => {
    let layer: DesignatorLayer = getNamedObject(design_object.layer);
    let linestyle = LayerToLinestyle(layer);
    if (SelectedDesignObject(draw_context)?.uuid === design_object.uuid) {
      linestyle.stroke = baseLineColor;
      linestyle.fill = baseLineColor;
    }

    return (
      <Line
        points={design_object.geometry.points}
        {...linestyle}
        opacity={opacity}
        design_object={design_object}
        strokeScaleEnabled={false}
      />
    );
  };

  const renderEditPoints = (
    design_object: LineObject,
    opacity = 1,
    color = null
  ) => {
    let layer = getNamedObject(design_object.layer);
    let linestyle = LayerToLinestyle(layer);
    if (SelectedDesignObject(draw_context)?.uuid === design_object.uuid) {
      linestyle.stroke = baseLineColor;
      linestyle.fill = baseLineColor;
    }

    let pts: number[][] = [];
    for (let i = 0; i < design_object.geometry.points.length; i += 2) {
      pts.push(design_object.geometry.points.slice(i, i + 2));
    }

    const ptToCircle = (pt, i) => {
      let [posx, posy] = pt;
      return (
        <Circle
          x={posx}
          y={posy}
          radius={basePointRadius}
          {...linestyle}
          opacity={opacity}
          design_object={design_object}
          line_index={i}
          onClick={handlePointClick}
        />
      );
    };

    return <Group>{pts.map((pt, i) => ptToCircle(pt, i))}</Group>;
  };

  const renderSection = (
    design_object: LineObject,
    opacity = 1,
    color = null
  ) => {
    let se: CrossSection = getNamedObject(
      design_object.details?.[0]?.internalDetails?.[0]
    ) as CrossSection;

    if (!se) {
      return;
    }

    let line = design_object.geometry.points;

    var base_value;
    if (se.baseCoordinates.type === "center") {
      base_value = 0;
      for (var i = 0; i < se.materials.length; i += 1) {
        let material = getNamedObject(se.materials[i]);
        base_value += material.thickness;
      }
      base_value /= 2;
    } else {
      base_value = se.baseCoordinates.value;
    }

    const renderOffsetLine = (
      line: number[],
      dist: number,
      layer: DesignatorLayer,
      bounding
    ) => {
      var newLine = offsetLine(line, dist);
      if (bounding) {
        for (var i in bounding) {
          let bounding_point = bounding[i];
          newLine[parseInt(i) * 2] = bounding_point[0];
          newLine[parseInt(i) * 2 + 1] = bounding_point[1];
        }
      }

      let linestyle = LayerToLinestyle(layer);
      if (color) {
        linestyle.stroke = color;
      }

      return (
        <Line
          points={newLine}
          {...linestyle}
          opacity={opacity}
          design_object={design_object}
        />
      );
    };

    let found_joint;

    let points = [];

    for (let joint of Object.values(canvasContext.jointObjects)) {
      for (let anchor of joint.anchors) {
        if (anchor.designObject.uuid === design_object.uuid) {
          let joint_res = CalculateJointExtension(joint)[design_object.uuid];

          if (!found_joint) {
            found_joint = {};
          }
          found_joint[anchor.pointIndex] = joint_res;
          points = points.concat(...joint_res);
        }
      }
    }

    var offsetLines = [];
    var pos = 0;
    for (var i = 0; i < se.materials.length; i += 1) {
      let material = getNamedObject(se.materials[i]);
      let layer: DesignatorLayer = getNamedObject(material.outlineLayer);

      let bounding_top;
      let bounding_bottom;

      if (found_joint) {
        bounding_top = {};
        bounding_bottom = {};
        for (let point_index in found_joint) {
          let all_boundings = found_joint[point_index];
          bounding_top[point_index] = all_boundings[i][0];
          bounding_bottom[point_index] = all_boundings[i][1];
        }
      }

      var bottom_line = renderOffsetLine(
        line,
        pos - base_value,
        layer,
        bounding_bottom
      );
      pos += material.thickness;
      var top_line = renderOffsetLine(
        line,
        pos - base_value,
        layer,
        bounding_top
      );
      offsetLines = offsetLines.concat([bottom_line, top_line]);
    }

    return (
      <Group key={design_object.uuid.concat("_section")}>
        {[...offsetLines]}
      </Group>
    );
  };

  const renderObject = (design_object: LineObject) => {
    let opacity = 1;
    let color;
    if (draw_context.scope) {
      opacity = 0.5;
    }
    if (SelectedDesignObject(draw_context)?.uuid === design_object.uuid) {
      color = selectedColor;
    }
    let current_scope;
    if (draw_context.scope?.uuid === design_object.uuid) {
      current_scope = (
        <RenderCurrentDrawingLine
          currentDrawing={design_object.geometry.points.slice(0, 2)}
          currentMousePos={design_object.geometry.points.slice(2)}
        />
      );
    }
    return (
      <>
        {renderSection(design_object, opacity, color)}
        {renderBaseLine(design_object, opacity, color)}
        {renderEditPoints(design_object, opacity, color)}
        <ClickArea design_object={design_object} />
        {current_scope}
      </>
    );
  };

  return (
    <Group key="render_design_objct">
      {Object.values(designObjectData).map(renderObject)}
    </Group>
  );
};
