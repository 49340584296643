import React from "react";
import List from "../components/List";

import styles from "./CYPage.module.scss";
import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const CYPage = () => {
  return (
    <div className={cx("test")}>
      <h1>hello, this is changyong's javascript file page</h1>
      <List type="column" attach="start" gap={2}>
        <div cl>test</div>
        <div>test2</div>
      </List>
    </div>
  );
};

export default CYPage;
